import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Chat from "@material-ui/icons/Class";
import VerifiedUser from "@material-ui/icons/Home";
import Fingerprint from "@material-ui/icons/SupervisorAccount";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

class ProductSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Our Education Services</h2>
            <h5 className={classes.description}>
            • Applying to public/private elementary, middle and high schools<br />
            • Applying to public/private colleges and universities (post and undergraduate)<br />
            • Assisting for study permits and temporary residence visas<br /> 
            • Holding custodianship for minor students<br />
            • Assistance with school deposit payments<br />
            • Picking up at the airport<br />
            • Finding accommodation for students<br />
            • Setting up student bank accounts, public health insurances and mobile plans
            </h5>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Post Secondary Education Institutions."
                list={[
                  "• University of Canada West",
                  "• British Columbia Institute of Technology (BCIT)",
                  "• Douglas College", 
                  "• Langara College",
                  "• Alexander College",
                  "• Alexander Academy", 
                  "• Cornerstone College",
                  "• Vancouver Community College"
                ]}
                icon={Chat}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Accommodation"
                description="Win International Education Service helps international students also to find homestay placements in Canada for convenient stay in country, in such provinces as British Columbia, Alberta, Saskatchewan, Manitoba, Ontario and Quebec. Give us a chance to find for you a friendly English Speaking Host Family and you will enjoy their company and comfortable living conditions."
                icon={VerifiedUser}
                iconColor="success"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Custodianship"
                description="If you are a prospective student in Canada under the age of 18 or 19 (depending on province), you will be required a custodian, a person who will be responsible for you during your stay in Canada, in order to obtain your study permit and visa by Citizenship and Immigration Canada(CIC). You can find the official website below. Win International Education Service has a great network of legal and official approved custodians all over Canada and can provide you one of them for your safety and convenient stay in Canada, and of course for study permit purposes. Before your arrival, we will provide all contacts and background information about your future custodian in Canada, in order for students and their parents will rely on reliable person, who can solve any possible problems or inconveniences, which may arise in new country during future stay."
                link={{address: "https://www.canada.ca/en/immigration-refugees-citizenship/services/study-canada/study-permit/prepare/minor-children.html",
                      text: "Citizenship and Immigration Canada Website"}}
                icon={Fingerprint}
                iconColor="danger"
                vertical
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(ProductSection);
 