import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import team1 from "assets/img/faces/avatar.jpg";
import team2 from "assets/img/faces/christian.jpg";
import team3 from "assets/img/faces/kendall.jpg";

class TeamSection extends React.Component {
  render() {
    const { classes } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    return (
      <div className={classes.section}>
        <h2 className={classes.title}>Our Success stories</h2>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  {/* <img src={team1} alt="..." className={imageClasses} /> */}
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Kaung Kyaw San
                  <br />
                  <small className={classes.smallTitle}>Alexander College - University transfer program</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                    Coming to Canada seemed merely just a dream at first. 
                    I had no friends or relatives there. The only information and help I got 
                    about Canada was via the internet. My dad and I decided to 
                    find out more about the country since it is a perfect place 
                    for international students and for emigrating. When we found Miss Indra, 
                    we thought she was a good chance for us to learn more about the country and 
                    to find out how I can move and study there. Guess what? Now I have successfully 
                    moved to Vancouver and started pursuing my interests and studies at College, 
                    all thanks to Miss Indra.
                    The services I got, such as applying for VISA or study permit, school enrolment, 
                    searching for homestay and hostels and other essential procedures required to 
                    settle down here went very smoothly. Now, my family back in Myanmar no longer 
                    needs to worry about me anymore too since we all trust her as my guardian or 
                    custodian. Finally, I could turn my dream into  reality by taking the opportunity 
                    to seek out Miss Indra’s help and support.
                  </p>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  {/* <img src={team1} alt="..." className={imageClasses} /> */}
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Htet Kaung San
                  <br />
                  <small className={classes.smallTitle}>BCIT</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                    My auntie got to know Miss Indra through her Facebook page. 
                    After I passed my 10th grade highSchool in Myanmar. 
                    We Contacted her to apply international school and apply for a student visa in Canada. 
                    She suggested us to apply BCIT. She provides very fast and professional service
                    for student visa application for a Myanmar Student. 
                    They give professional advice for schooling in Canada. I get my Student Visa within a month. 
                    Now I can come and study to BCIT. Thank you Miss Indra.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  {/* <img src={team1} alt="..." className={imageClasses} /> */}
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Ingyin Maung Maung
                  <br />
                  <small className={classes.smallTitle}>Alexander College - University transfer program</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                    Win International Education service is the best agency for me. 
                    I know her through her Facebook page. Miss Indra is very attentive 
                    and she goes beyond miles for me to come and study in Canada. 
                    Thank you WIN and Miss Indra.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  {/* <img src={team1} alt="..." className={imageClasses} /> */}
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Min Thant
                  <br />
                  <small className={classes.smallTitle}>Alexander College - University transfer program</small>
                </h4>
                <CardBody>
                 <p className={classes.description}>
                   WIN is very professional and very reliable Education agency. 
                   WIN is one stop education service. Now I am in Canada studying at Alexander. Thank you WIN.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  {/* <img src={team1} alt="..." className={imageClasses} /> */}
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Min Htet Kyaw
                  <br />
                  <small className={classes.smallTitle}>BCIT - ISEP Program plus</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                    I know Miss Indra through her facebook page. She is very professional. 
                    I would recommend her for future students. Thank you WIN. 
                    Now I am studying in BCIT.     
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  {/* <img src={team1} alt="..." className={imageClasses} /> */}
                </GridItem>
                <h4 className={classes.cardTitle}>
                Htin Shar
                  <br />
                  <small className={classes.smallTitle}>BCIT - ISEP Program plus</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                    My mom knows Miss Indra through her facebook page. 
                    She is very trust-worthy agent. I am happy that Miss Indra establish 
                    WIN International Education Service for Myanmar Students like us. 
                    Thank you WIN. 
                  </p>
                </CardBody>
              </Card>
            </GridItem>     
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  {/* <img src={team1} alt="..." className={imageClasses} /> */}
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Khin Mar
                  <br />
                  <small className={classes.smallTitle}>VCC</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                    Thank you for the help with admission to college and visa to Canada. 
                    Fast Work, deep understanding of Singapore market and great customer service.
                  </p>
                </CardBody>
              </Card>
            </GridItem>     
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  {/* <img src={team1} alt="..." className={imageClasses} /> */}
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Khant Kyaw Htinr
                  <br />
                  <small className={classes.smallTitle}>Langara College</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                    Thank you WIN to provide the amazing opportunity to upgrade myself in Canada. 
                    I got admission to langara college and visa to Canada. 
                    WIN is the one stop education agency you can count on.
                  </p>
                </CardBody>
              </Card>
            </GridItem>     
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  {/* <img src={team1} alt="..." className={imageClasses} /> */}
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Nandar Aye
                  <br />
                  <small className={classes.smallTitle}>Douglas College</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                    I know Miss Indra through her FB page. Although I am in the late 30, 
                    the professional team from  WIN to help me to get admissions and get 
                    my visa approved. Really appreciate your hard work on my case. 
                    Thank you WIN and Miss Indra. 
                  </p>
                </CardBody>
              </Card>
            </GridItem>            
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(teamStyle)(TeamSection);

